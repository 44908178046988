import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addTransaction } from '../../actions/index';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DatePicker } from 'material-ui-pickers';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import IconAdd from '@material-ui/icons/Add';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowLeft from '@material-ui/icons/ArrowLeft';

class TxnForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rowID: this.props.account.rowID,
			date: (new Date()).toISOString(),
			title: '',
			txnType: 'review',
			desc: '',
			amount: '',
			listID: this.props.account.listID,
			fullName: this.props.account.fullName,
			buttonState: 'icon__add icon__open',
			formState: 'form__container form__inactive',
			formActive: false,
			txnAdded: null,
			loader: false
		}
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.formActive = this.formActive.bind(this);
	}

  componentDidUpdate(prevProps, prevState) {
  	if(prevState.rowID !== this.props.account.rowID) {
  		this.setState({ rowID: this.props.account.rowID })
  	}
  	if(prevState.fullName !== this.props.account.fullName) {
  		this.setState({ fullName: this.props.account.fullName })
  	}
  	if(this.state.txnAdded === 'pending') {
  		if(this.props.transactions.status === 200) {
  			this.setState({
  				txnAdded: null,
  				formActive: false,
					formState: 'form__container form__inactive',
					buttonState: 'icon__add icon__open',
					date: (new Date()).toISOString(),
					title: '',
					txnType: 'review',
					desc: '',
					amount: '',
					loader: false
  			}, () => {
  				this.props.handler();
  			})
  		}
  	}
  }

  formActive() {
		if(!this.state.formActive) {
			this.setState({ 
				formActive: true,
				formState: 'form__container form__active',
				buttonState: 'icon__add icon__close',
				title: '',
				desc: '',
				amount: '',
				date: (new Date()).toISOString()
			});
		} else {
			this.setState({ 
				formActive: false,
				formState: 'form__container form__inactive',
				buttonState: 'icon__add icon__open'
			});
		}
	}

  handleClose() {
  	this.setState({ formState: 'form__container form__inactive' })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDateChange = date => {
  	this.setState({ date: date });
  };

  handleSubmit(e) {
  	e.preventDefault();
  	let data = {
  		rowID: this.state.rowID,
  		date: (new Date(this.state.date)).toISOString().split('.')[0],
  		time_created: (new Date()).toISOString().split('.')[0],
  		title: this.state.title,
  		desc: this.state.desc,
  		amount: this.state.amount,
  		list_id: this.state.listID,
  		full_name: this.state.fullName,
  		txn_type: this.state.txnType
  	};
  	this.setState({ 
  		txnAdded: 'pending',
  		loader: true
  	});
  	this.props.addTransaction(data);
  }

	render() {
		return (
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<LoadingAnimation variant="front" active={this.state.loader} />
				<form className={ this.state.formState }>
					<Paper className="form__fixed">
						<List>
							<ListItem 
								dense={true}
								disableGutters={true}
								className="form__list-item"
							>
								<TextField 
									fullWidth={true}
									variant="outlined"
									label="Title"
									id="txn_title"
									onChange={this.handleChange('title')}
									value={this.state.title}
									classes={{ root: 'form__input form__half' }}
								/>
								<TextField
				          select
				          classes={{ root: 'form__input form__half' }}
				          variant="outlined"
				          label="Type"
				          value={this.state.txnType}
				          onChange={this.handleChange('txnType')}
				        >
				        	<MenuItem key='review' value='review'>Review</MenuItem>
				        	<MenuItem key='draw' value='draw'>Draw</MenuItem>
				        	<MenuItem key='inspection' value='inspection'>Inspection</MenuItem>
				        </TextField>
							</ListItem>
							<ListItem
								dense={true}
								disableGutters={true}
								className="form__list-item"
								>
								<div className="picker form__half">
				          <DatePicker
				            autoOk
				            label="Date"
				            clearable
				            variant="outlined"
				            format="MM/dd/yyyy"
				            leftArrowIcon={<ArrowLeft />}
				            rightArrowIcon={<ArrowRight />}
				            maxDateMessage="Date must be less than today"
				            value={this.state.date}
				            onChange={this.handleDateChange}
				            classes={{ root: 'form__input' }}
				          />
				        </div>
				        <TextField
				          id="txn_amount"
				          variant="outlined"
				          label="Amount"
				          value={this.state.amount}
				          onChange={this.handleChange('amount')}
				          classes={{ root: 'form__input form__half' }}
				          InputProps={{
				            startAdornment: <InputAdornment position="start">$</InputAdornment>,
				          }}
				        />
							</ListItem>
							<ListItem 
								dense={true}
								disableGutters={true}
								className="form__list-item"
							>
								<TextField
				          label="Description"
				          id="txn_desc"
									multiline
									rows={6}
									value={this.state.desc}
									onChange={this.handleChange('desc')}
									variant="outlined"
									classes={{ root: 'form__input form__full' }}
				        />
							</ListItem>
							<ListItem 
								dense={true}
								disableGutters={true}
								className="form__list-item"
							>
				        <Button 
									type="submit"
									className="form__button"
									onClick={this.handleSubmit}
								>
									Submit
								</Button>
							</ListItem>
						</List>
					</Paper>
				</form>
				<IconAdd 
        	className={ this.state.buttonState }
        	onClick={ this.formActive }
        />
			</MuiPickersUtilsProvider>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ addTransaction }, dispatch);
}

function mapStateToProps({ transactions }) {
	return { transactions };
}

export default connect(mapStateToProps, mapDispatchToProps)(TxnForm);