import React from 'react';

const QuickBooks = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
	    <g id="Co-branding" transform="translate(-219.000000, -1158.000000)">
	        <g id="Group-36" transform="translate(219.000000, 1158.000000)">
	            <path d="M0.817375,23.3487179 C0.817375,12.294359 9.82189959,3.33435897 20.9311906,3.33435897 C32.0404816,3.33435897 41.0450061,12.294359 41.0450061,23.3487179 C41.0450061,34.4010256 32.0404816,43.3630769 20.9311906,43.3630769 C9.82189959,43.3630769 0.817375,34.4010256 0.817375,23.3487179" id="Fill-8" fill="#2CA01C"/>
	            <path d="M14.2243865,15.5647179 C9.90353402,15.5647179 6.40314877,19.0498462 6.40314877,23.3472821 C6.40314877,27.6467692 9.90353402,31.1318974 14.2243865,31.1318974 L15.3417061,31.1318974 L15.3417061,28.2395897 L14.2243865,28.2395897 C11.5135463,28.2395897 9.30776762,26.0447179 9.30776762,23.3472821 C9.30776762,20.6498462 11.5135463,18.4549744 14.2243865,18.4549744 L16.9104889,18.4549744 L16.9104889,33.5770256 C16.9104889,35.1749744 18.2112799,36.4693333 19.8151078,36.4693333 L19.8151078,15.5647179 L14.2243865,15.5647179 Z" id="Fill-9" fill="#FEFEFE"/>
	            <path d="M27.6375824,31.1325128 C31.9584348,31.1325128 35.4588201,27.6473846 35.4588201,23.3499487 C35.4588201,19.0504615 31.9584348,15.5653333 27.6375824,15.5653333 L26.5202627,15.5653333 L26.5202627,18.457641 L27.6375824,18.457641 C30.3484225,18.457641 32.5542012,20.6525128 32.5542012,23.3499487 C32.5542012,26.0473846 30.3484225,28.2422564 27.6375824,28.2422564 L24.9514799,28.2422564 L24.9514799,13.1202051 C24.9514799,11.5222564 23.6506889,10.2278974 22.0468611,10.2278974 L22.0468611,31.1325128 L27.6375824,31.1325128 Z" id="Fill-10" fill="#FEFEFE"/>
	        </g>
	    </g>
		</svg>
	);
}

export default QuickBooks;