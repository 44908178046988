const initialState = {
  hasLoginError: false,
  isLoggingIn: false,
  token: null
}

export default function(state = initialState, action) {
	switch(action.type) {
		case 'LOG_IN':
			console.log(action.payload)
			return { ...state, ...action.payload }
		case 'LOG_OUT':
			return { ...state, ...action.payload }
		case 'LOG_IN_WITH_ERROR':
      return { ...state, ...action.payload }
		default:
			return state;
	}
}