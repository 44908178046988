const initialState = {
	loans: [],
	escrow: [],
	last_updated: ''
}

export default function(state = initialState, action) {
	switch(action.type) {
		case 'FETCH_LOANS':
			return { ...state, loans: action.payload.data.response, last_updated: action.payload.data.last_updated }
		case 'FETCH_ESCROW':
			return { ...state, escrow: action.payload.data.response }
		default:
			return state;
	}
}