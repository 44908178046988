import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IconClose from '@material-ui/icons/Close';

class Marker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			className: 'markers__marker markers__map-marker'
		};
		this.handleClick = this.handleClick.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if(nextProps.markerType === 'legend') {
			if(nextProps.activeStatus) {
				return {
					className: 'markers__marker markers__legend active'
				}
			} else {
				return {
					className: 'markers__marker markers__legend'
				}
			}
		}
		return null;
	}

	handleClick() {
		if(this.props.markerType !== 'legend') {
			this.setState({
				className: 'markers__marker markers__map-marker active'
			})
		}
	}

	handleClose() {
		if(this.props.markerType !== 'legend') {
			this.setState({
				className: 'markers__marker markers__map-marker'
			})
		}
	}

	render() {
		return(
			<div className={this.state.className}>
				<div className="marker__popup">
					<Link to={`/single/${this.props.listID}`}>
						<h3>{this.props.projectName}</h3>
					</Link>
					<IconClose className="marker__icon" onClick={this.handleClose} />
				</div>
				<img onClick={this.handleClick} alt="marker" src={this.props.circle} />
			</div>
		);
	}
}

export default Marker;