import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMore from '@material-ui/icons/ExpandMore';

const filterList = [ 
	{
		'value': 'alpha_a_to_z',
		'label': 'Alphabetical (A-Z)'
	},
	{
		'value': 'alpha_z_to_a',
		'label': 'Alphabetical (Z-A)'
	},
	{
		'value': 'balance',
		'label': 'Balance'
	},
	{
		'value': 'date_asc',
		'label': 'Date (ASC)'
	},
	{
		'value': 'date_desc',
		'label': 'Date (DESC)'
	}
]

class ProjectList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sort: null,
			accountsToDisplay: [],
			anchorEl: null
		}
		this.renderList = this.renderList.bind(this);
		this.resort = this.resort.bind(this);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.state.sort !== prevState.sort) {
			this.setState({
				accountsToDisplay: this.resort(this.props.accounts, this.state.sort)
			});
		}
		if(this.state.sort === null) {
			this.setState({
				sort: 'alpha_a_to_z'
			})
		}
	}

	handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  filterChange = name => event => {
		this.setState({
      sort: name,
      anchorEl: null
    });
	}

	paginateProjects() {
		let currentPage = this.state.offset;
		let nextPage = currentPage + this.state.perPage;
		this.setState({
			offset: nextPage
		});
	}

	renderList(account) {
		let accountAddress = '';
		if(account.address) {
			accountAddress = ' - ' + account.address
		}
		return (
			<ListItem key={account.id} >
				<Link className="project-list__link" to={`/single/${account.list_id}`}>
					<span className="project-list__title">{ account.name }</span>
					<span className="project-list__address">{ accountAddress }</span>
				</Link>
			</ListItem>
		)
	}

	resort(accounts, sort) {
		return accounts.sort((a,b) => {
			switch(sort) {
				case 'alpha_a_to_z':
					if(a.name < b.name) { return -1; }
					if(a.name > b.name) { return 1; }
					return 0;
				case 'alpha_z_to_a':
					if(a.name > b.name) { return -1; }
					if(a.name < b.name) { return 1; }
					return 0;
				case 'date_asc':
					return new Date(a.time_created) - new Date(b.time_created);
				case 'date_desc':
					return new Date(b.time_created) - new Date(a.time_created);
				case 'balance':
					return parseFloat(b.balance) - parseFloat(a.balance);
				default:
					return new Date(a.time_created) - new Date(b.time_created);
			}
		});
	}

	render() {
		const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
		return(
			<div className="projects-list">
				<div className="projects-list__heading">
						<h2>Projects</h2>
						<div className="filter__container">
							<div className="filter__toggle" onClick={this.handleClick}>
								<span>Filter</span>
								<ExpandMore />
							</div>
							<Menu
			          className="filter__menu"
			          anchorEl={anchorEl}
			          open={open}
	          		onClose={this.handleClose}
			        >
			          { filterList.map((option) => {
			          	let selected = false;
			          	if(this.state.sort === option.value) {
			          		selected = true;
			          	}
			          	return (
				            <MenuItem 
				            	className="filter__menu-item"
				            	onClick={this.filterChange(option.value)}
				            	selected={selected}
				            	key={option.value} >
				              {option.label}
				            </MenuItem>
				          	)
				          }) 
				        }
			        </Menu>
			       </div>
					</div>
				<Paper className="paper">
					<div className="projects-list__projects">
						<List className="projects-list__list">
							{this.state.accountsToDisplay.map(this.renderList)}
						</List>
					</div>
				</Paper>
			</div>
		);
	}
}

export default ProjectList;