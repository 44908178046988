const initialState = {
	single: [],
	all: [],
	status: 100
}

export default function(state = initialState, action) {
	switch(action.type) {
		case 'TRANSACTION_PENDING':
			return { ...state, status: action.status }
		case 'FETCH_SINGLE_TRANSACTIONS':
			return { ...state, single: action.payload.data, status: action.payload.status }
		case 'FETCH_ALL_TRANSACTIONS':
			return { ...state, all: action.payload.data, status: action.payload.status }
		case 'ADD_TRANSACTION':
			return { ...state, status: action.payload.status }
		case 'DELETE_TRANSACTION':
			return { ...state, status: action.payload.status }
		default:
			return state;
	}
}