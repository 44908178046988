import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { fetchAccount, updateAccount, fetchSingleTransactions, fetchEscrow } from '../../actions/index';
import Script from 'react-load-script';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import TxnList from '../../components/TxnList/TxnList';
import TxnForm from '../../components/TxnForm/TxnForm';
import Paper from '@material-ui/core/Paper';
import IconEdit from '@material-ui/icons/Edit';
import IconSave from '@material-ui/icons/PlaylistAddCheck';
import IconCancel from '@material-ui/icons/Close';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import IconPrint from '../../assets/print.svg';

class Single extends Component {
	constructor(props) {
		super(props);
		this.state = {
			scriptError: false,
			scriptLoaded: false,
			shouldAddListener: true,
			pathname: null,
			history: [],
			upcoming: [],
			metaDisabled: true,
			updateSingle: null,
			metaEdit: 'single__meta-overview inactive',
			totalBalance: null,
			escrow: null,
			created: null,
			outstanding: null,
			accountActive: null,
			rowID: null,
			name: null,
			listID: null,
			displayName: '',
			fullName: null,
			address: '',
			lat: null,
			lng: null,
			phone: '',
			watchlist: false,
			reminder: false,
			action: null
		}	
		this.filterTransactions = this.filterTransactions.bind(this);
		this.getOutstanding = this.getOutstanding.bind(this);
		this.getReminder = this.getReminder.bind(this);
		this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
		this.handleScriptError = this.handleScriptError.bind(this);
		this.handleScriptLoad = this.handleScriptLoad.bind(this);
		this.metaCancel = this.metaCancel.bind(this);		
		this.metaEdit = this.metaEdit.bind(this);
		this.updateSingle = this.updateSingle.bind(this);
	}

	componentDidMount() {
		this.props.fetchAccount(this.props.match.params.listID);
		this.props.fetchSingleTransactions(this.props.match.params.listID);
		this.props.fetchEscrow();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.transactions === null || this.props.single === null) return;
		// Add autocomplete listener once scriped is loaded
		if(this.state.scriptLoaded === true && this.state.shouldAddListener === true) {
  		this.setState({ shouldAddListener: false }, () => {
  			this.autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('autocomplete')); 
  			this.autocomplete.setFields(['formatted_address', 'geometry']);
		  	this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  		})
		}
		if(this.props.accounts.escrow.length > 0 && this.state.escrow === null) {
			let escrow = this.props.accounts.escrow.filter((x) => { 
				return x.list_id === this.props.single.escrow 
			});
			if(escrow.length > 0) {
				this.setState({
					escrow: '($' + parseFloat(escrow[0].total_balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' escrow)'
				})
			}
		}
		// Reset state when project is changed
		if(this.props.single.id !== this.state.rowID) {
			let t = this.props.single.time_created.split(/[- :]/)
			let created = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
			this.setState({
				accountActive: this.props.single.is_active ? 'Active' : 'Inactive',
				pathname: this.props.location.pathname,
				rowID: this.props.single.id,
				displayName: this.props.single.display_name ? this.props.single.display_name : '',
				name: this.props.single.name,
				fullName: this.props.single.full_name,
				phone: this.props.single.phone ? this.props.single.phone : '',
				address: this.props.single.address ? this.props.single.address : '',
				listID: this.props.single.list_id,
				lat: this.props.single.lat,
				lng: this.props.single.lng,
				escrow: null,
				totalBalance: parseFloat(this.props.single.total_balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
				watchlist: !!+parseInt(this.props.single.watchlist) ? true : false,
				reminder: !!+parseInt(this.props.single.reminder) ? true : false,
				created: created.getMonth()+1 + '/' + created.getDate() + '/' + created.getFullYear(),
				outstanding: this.getOutstanding(t, new Date()),
				daysUntil: this.getReminder().daysUntil
			});
		}
		// Reset component when path is changed
		if(prevState.pathname !== null && prevState.pathname !== this.props.location.pathname) {
			this.props.fetchAccount(this.props.match.params.listID);
			this.props.fetchSingleTransactions(this.props.match.params.listID);
			this.setState({
				pathname: this.props.location.pathname
			})
		}
		// Reset state after account or transaction change
		if(this.state.updateSingle === 'pending') {
			this.setState({
				metaDisabled: true,
				updateSingle: 'done',
				metaEdit: 'single__meta-overview inactive',
			}, () => {
				this.props.updateAccount(this.state);
				this.props.fetchAccount(this.props.match.params.listID);
				this.props.fetchSingleTransactions(this.props.match.params.listID);
			});
		}
	}

	filterTransactions(transactions, type) {
		if(transactions !== null) {
			let txns = [];
			if(type === 'upcoming') {
	  		txns = transactions.single.filter((txn) => {
	  			let t = txn.txn_date.split(/[- :]/);
	  			return new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]) > new Date();
	  		})
	  		if(this.state.reminder === true) {
	  			let reminder = this.getReminder();
	  			let reminderTxn = {
	  				txn_title: '30 Day Reminder',
	  				txn_date: reminder.date,
	  				memo: reminder.daysUntil,
	  				txn_type: 'thirty_days',
	  				id: 'thirty-days',
	  				amount: 0
	  			}
	  			txns.push(reminderTxn);
	  		}
	  	} else {
	  		txns = transactions.single.filter((txn) => {
	  			let t = txn.txn_date.split(/[- :]/);
	  			return new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]) < new Date()
	  		})
	  	}
	  	txns.sort(function(a,b){
	  		let at = '';
				let bt = '';
				let adate = '';
				let bdate = '';
				if(a.txn_type === 'thirty_days') {
					adate = new Date(a.txn_date);
				} else {
					at = a.txn_date.split(/[- :]/);
					adate = new Date(at[0], at[1]-1, at[2], at[3], at[4], at[5]);
				}
				if(b.txn_type === 'thirty_days') {
					bdate = new Date(b.txn_date);
				} else {
					bt = b.txn_date.split(/[- :]/);
					bdate = new Date(bt[0], bt[1]-1, bt[2], bt[3], bt[4], bt[5]);
				}
				return bdate - adate;
			});
			if(txns.length === 0) {
				txns = []
			}
	  	return txns;
		}
	}

  getOutstanding(t, today) {
  	let msPerDay = 1000 * 60 * 60 * 24;
  	let utcCreated = Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
  	let utcToday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
  	return Math.floor((utcToday - utcCreated) / msPerDay);
  }

  getReminder() {
  	let t = this.props.single.time_created.split(/[- :]/);
  	let thirtyDays = 24 * 3600 * 1000 * 30;
		let today = (new Date()).getTime();
		let start = (new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]).getTime());
		let reminder = start;
		while(reminder < today) {
			reminder = reminder + thirtyDays;
		}
		let days = Math.floor((reminder - today) / (24 * 60 * 60 * 1000));
		let daysUntil = days + ' days';
		if(days === 0) {
			daysUntil = 'Due today';
		}
		return {
			date: new Date(reminder).toISOString(),
			daysUntil: daysUntil
		}
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handlePlaceChanged() {
  	let location = this.autocomplete.getPlace();
		if(location) {
			this.setState({
	  		address: location.formatted_address,
	  		lat: location.geometry.location.lat(),
	  		lng: location.geometry.location.lng()
	  	})
		}
  }

  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    if(this.state.scriptLoaded === false) {
      this.setState({ scriptLoaded: true })
    }
  }

  handleToggle = name => event => {
		this.setState({ 
			[name]: !this.state[name],
			updateSingle: 'pending'
		});
  }

	metaCancel() {
		this.setState({
			displayName: this.props.single.display_name,
			phone: this.props.single.phone,
			address: this.props.single.address,
			metaDisabled: true, 
			metaEdit: 'single__meta-overview inactive'
		})
	}

	metaEdit() {
		if(this.state.metaDisabled) {
			this.setState({ 
				metaDisabled: false, 
				metaEdit: 'single__meta-overview active'
			})
		} else {
			this.setState({ 
				metaDisabled: true, 
				metaEdit: 'single__meta-overview inactive'
			})
		}
	}

	updateSingle() {
		this.setState({ updateSingle: 'pending' });
	}

	render() {
		if( this.state.rowID !== null ) {
			let reminderText = this.state.reminder ? 'Reminder (' +this.state.daysUntil + ')' : 'Reminder';
			let upcomingTransactions = this.filterTransactions(this.props.transactions, 'upcoming');
			let historyTransactions = this.filterTransactions(this.props.transactions, 'history');
			return(
				<div>
					<Script 
            url="https://maps.googleapis.com/maps/api/js?key=AIzaSyDsEXS4m8yW8JGVOtq-Qzs3CjQN8A_w0BQ&libraries=places"
            onLoad={this.handleScriptLoad}
            onError={this.handleScriptError}
          />
					<div className="single__container">
						<h1>{this.state.name}</h1>
						<div className="single__meta">
							<div className={this.state.metaEdit}>
								<Paper className="paper">
									<form>
										<FormControl className="single__field-container" >
											<Input 
												className="single__field" 
												value={this.state.displayName} 
												onChange={this.handleChange('displayName')}
												placeholder="Title"
												/>
										</FormControl>
										<FormControl className="single__field-container" >
											<Input 
												className="single__field" 
												value={this.state.address} 
												onChange={this.handleChange('address')}
												id="autocomplete"
											/>
										</FormControl>
										<FormControl className="single__field-container" >
											<Input 
												className="single__field" 
												value={this.state.phone} 
												onChange={this.handleChange('phone')}
												placeholder="Phone"
												/>
										</FormControl>
										<p className="single__active">{this.state.accountActive}</p>
										<div className="icon__save-cancel">
											<IconSave className="icon__save" onClick={this.updateSingle} />
											<IconCancel className="icon__cancel" onClick={this.metaCancel} />
										</div>
										<div className="icon__edit">
											<IconEdit onClick={this.metaEdit} />
										</div>
									</form>
								</Paper>
							</div>
							<div className="single__meta-details">
								<Paper className="paper">
									<p>{this.state.created} ({this.state.outstanding} days)</p>
									<p>${this.state.totalBalance} {this.state.escrow}</p>
									<p className="single__toggle">
										{reminderText}
										<Switch 
											className="single__toggle-switch"
											checked={this.state.reminder}
											onChange={this.handleToggle('reminder')}
											classes={{ 
												bar: 'single__toggle-switch-bar',
												icon: 'single__toggle-switch-icon',
												checked: 'single__toggle-switch-checked'
											}}
										/>
									</p>
									<p className="single__toggle">
										Watchlist
										<Switch 
											className="single__toggle-switch"
											checked={this.state.watchlist}
											onChange={this.handleToggle('watchlist')}
											classes={{ 
												bar: 'single__toggle-switch-bar',
												icon: 'single__toggle-switch-icon',
												checked: 'single__toggle-switch-checked'
											}}
										/>
									</p>
								</Paper>
							</div>
						</div>
						<TxnList 
							type="upcoming" 
							txns={upcomingTransactions}
							handler={this.updateSingle}
						/>
						<TxnList 
							type="history" 
							txns={historyTransactions} 
							handler={this.updateSingle}
						/>
						<TxnForm 
							activeClass={this.state.formActiveClass}
							account={this.state}
							handler={this.updateSingle}
						/>
					</div>
					<div className="single__print-container">
						<div className="single__print" onClick={ () => { window.print() }}>
							<img src={IconPrint} alt="print" />
							<h4>Print Project</h4>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<LoadingAnimation active={true} />
			);
		}
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchAccount, updateAccount, fetchSingleTransactions, fetchEscrow }, dispatch);
}

function mapStateToProps({ single, transactions, accounts }) {
	return { single, transactions, accounts };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Single));