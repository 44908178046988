import axios from 'axios';

export const fetchAllTransactions = () => {
	return(dispatch, getState) => {
		return axios.get(`${process.env.REACT_APP_WP_REST_API}/transactions`, {
      params: {
        token: getState().user.token
      }
    })
		.then((response) => {
			dispatch({
				type: 'FETCH_ALL_TRANSACTIONS',
				payload: response
			})
		})
		.catch((error) => {
			if(error.response.status === 403) {
    		dispatch({
					type: 'LOG_OUT',
					payload: { token: '', hasLoginError: false, isLoggingIn: false, expired: true }
				})
    	} else {
    		console.log(error);
    	}
		})
	}
}

export const fetchSingleTransactions = (list_id) => {
	return(dispatch, getState) => {
		return axios.get(`${process.env.REACT_APP_WP_REST_API}/transactions/list_id/${list_id}?_=${Math.floor(Math.random() * 1000000)}`, {
      params: {
        token: getState().user.token
      }
    })
		.then((response) => {
			dispatch({
				type: 'FETCH_SINGLE_TRANSACTIONS',
				payload: response
			})
		})
		.catch((error) => {
			console.log(error);
		})
	}
}

export const addTransaction = (data) => {
	return(dispatch, getState) => {
		dispatch({
			type: 'TRANSACTION_PENDING',
			status: 202
		})
		return axios.post(`${process.env.REACT_APP_WP_REST_API}/transactions`, {
      params: {
        token: getState().user.token,
        data
      }
    })
		.then((response) => {
			dispatch({
				type: 'ADD_TRANSACTION',
				payload: response
			})
		})
		.catch((error) => {
			if(error.response.status === 403) {
    		dispatch({
					type: 'LOG_OUT',
					payload: { token: '', hasLoginError: false, isLoggingIn: false, expired: true }
				})
    	} else {
    		console.log(error);
    	}
		})
	}
}

export const deleteTransaction = (id) => {
	return(dispatch, getState) => {
		dispatch({
			type: 'TRANSACTION_PENDING',
			status: 202
		})
		return axios.delete(`${process.env.REACT_APP_WP_REST_API}/transactions`, {
		 params: { 
		 	token: getState().user.token,
		 	id: id 
		 } 
		})
		.then((response) => {
			dispatch({
				type: 'DELETE_TRANSACTION',
				payload: response,
			})
		})
		.catch((error) => {
			if(error.response.status === 403) {
    		dispatch({
					type: 'LOG_OUT',
					payload: { token: '', hasLoginError: false, isLoggingIn: false, expired: true }
				})
    	} else {
    		console.log(error);
    	}
		})
	}
}