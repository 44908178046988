import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logIn, logOut } from './actions/index';
import Nav from './components/Nav/Nav';
import Overview from './containers/Overview/Overview';
import Single from './containers/Single/Single';
import Borrowers from './containers/Borrowers/Borrowers';
import Reminders from './containers/Reminders/Reminders';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import logo from './assets/logo.svg';
import './scss/style.scss';
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerClass: 'container',
      isUserLoggedIn: false,
      username: '',
      password: '',
      hasLoginError: 'auth__errors',
      shouldComponentUpdate: false,
      scriptLoaded: false,
      expired: false
    }
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.renderError = this.renderError.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
    if(nextProps.user.hasLoginError === true) {
      return({ hasLoginError: 'auth__errors active' })
    }
    if(nextProps.user.expired === true) {
      return({ expired: true })
    }
    return({ hasLoginError: 'auth__errors' })
  }

  componentDidMount() {
    // if(process.env.REACT_APP_DEV_USER && process.env.REACT_APP_DEV_PASS) {
    //   this.props.logIn(process.env.REACT_APP_DEV_USER, process.env.REACT_APP_DEV_PASS);
    // }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleLogin(evt) {
    evt.preventDefault();
    this.props.logIn(this.state.username, this.state.password);
  }

  handleLogout(evt) {
    evt.preventDefault();
    this.props.logOut();
    localStorage.clear();
  }

  renderError() {
    if(this.props.user.hasLoginError === true) {
      return(<p className="auth__error">Invalid username or password.</p>)
    } else if(this.state.expired === true) {
      return(<p className="auth__error">Session expired, please log in.</p>)
    } else {
      return(<div></div>)
    }
  }

  render() {
    let shouldLogIn = false;
    if(this.props.user.token !== '' && this.props.user.token !== null) {
      shouldLogIn = true;
      localStorage.setItem('shouldLogIn', 'true');
    } else if(localStorage.getItem('shouldLogIn') === 'true') {
      shouldLogIn = true;
    }
    if(shouldLogIn === true) {
      return (
        <Router basename={'/mmlending.com/'}>
          <div className={this.state.containerClass}> 
            <header>
              <Nav handler={this.handleLogout} accounts={this.props.accounts} />
            </header>
            <div className="app">
              <div>
                <Route exact path="/" component={Overview} />
                <Route path="/single/:listID" component={() => <Single scriptLoaded={this.state.scriptLoaded} />} />
                <Route path="/borrowers/" component={Borrowers} />
                <Route path="/reminders/" component={Reminders} />
              </div>
            </div>
          </div>
        </Router>
      );
    } else {
      return (
        <div className="app">
          <form className="login__form">
            <Paper className="paper">
              <img src={logo} alt="MMLending" />
              <TextField
                label="Username"
                type="text"
                margin="normal"
                id="username"
                onChange={this.handleChange('username')}
                variant="outlined"
                autoComplete=""
              />
              <TextField
                id="password"
                label="Password"
                type="password"
                margin="normal"
                onChange={this.handleChange('password')}
                variant="outlined"
                autoComplete=""
              />
              <div className="auth__helpers">
                {this.renderError()}
                <p className="auth__reset">
                  <a href="http://app.mmlending.com/wp/wp-login.php?action=lostpassword">Reset Password?</a>
                </p>
              </div>
              <Button type="submit" onClick={this.handleLogin}>Log In</Button>
            </Paper>
          </form>
        </div>
      )
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logIn, logOut }, dispatch);
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
