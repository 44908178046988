import React, { Component } from 'react';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

class Sidebar extends Component {
	render() {
		if(this.props.activeAccounts === null || this.props.loansOut.length  === null) {
			return(
				<LoadingAnimation active={true} />
			)
		} else {
			let lastUpdated = new Date(parseInt(this.props.lastUpdated) * 1000);
			return(
				<div className="sidebar">
					<h2>Overview</h2>
					<Paper className="paper">
						<List className="sidebar__list">
							<ListItem>{ this.props.activeAccounts.length } active accounts</ListItem>
							<ListItem>{ this.props.loansOut.length } loans out</ListItem>
							<ListItem>${ parseFloat(this.props.totalBalance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") } in loans</ListItem>
							<ListItem>${ parseFloat(this.props.totalEscrow).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") } in escrow</ListItem>
							<ListItem>Synced {lastUpdated.getMonth()+1 + '/' + lastUpdated.getDate() + '/' + lastUpdated.getFullYear() + ', ' + lastUpdated.toLocaleTimeString('en-US') }</ListItem>
						</List>
					</Paper>
				</div>
			);
		}
	}
}

export default Sidebar;