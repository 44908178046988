import { combineReducers } from 'redux';
import Accounts from './accounts';
import Single from './single';
import Transactions from './transactions';
import User from './user';

const rootReducer = combineReducers({
  accounts: Accounts,
 	single: Single,
 	transactions: Transactions,
  user: User
});

export default rootReducer;
