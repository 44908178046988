import axios from 'axios';

export const fetchAccount = (list_id) => {
	return(dispatch, getState) => {
		return axios.get(`${process.env.REACT_APP_WP_REST_API}/accounts/list_id/${list_id}`, {
      params: {
        token: getState().user.token
      }
    })
		.then((response) => {
    	dispatch({
				type: 'FETCH_ACCOUNT',
				payload: response
			})
    })
    .catch((error) => {
    	console.log(error);
 		})
	}
}

export const updateAccount = (data) => {	
	return(dispatch, getState) => {
		return axios.post(`${process.env.REACT_APP_WP_REST_API}/accounts`, {
      params: {
        token: getState().user.token,
        data: data
      }
    })
		.then((response) => {
			dispatch({
				type: 'UPDATE_ACCOUNT',
				payload: response
			})
		})
		.catch((error) => {
			console.log(error);
		})
	}
}