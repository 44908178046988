import axios from 'axios';

export const logIn = (username, password) => {
	return (dispatch, getState) => {
    dispatch({
      payload: { hasLoginError: false, isLoggingIn: true },
      type: 'IS_LOGGING_IN'
    })
    return axios.post(`${process.env.REACT_APP_WP_REST_API}/login`, {
      username: username.trim(),
      password: password.trim()
    })
    .then((response) => {
      console.log(response);
      dispatch({
        payload: {...response.data, ...{ hasLoginError: false, isLoggingIn: false } },
        type: 'LOG_IN'
      })
    })
    .catch((error) => {
      dispatch({
        payload: {},
        type: 'LOG_OUT'
      })
      dispatch({
        payload: { hasLoginError: true, isLoggingIn: false },
        type: 'LOG_IN_WITH_ERROR'
      })
    })
  }
}

export const logOut = () => {
	return(dispatch, getState) => {
		axios.post(`${process.env.REACT_APP_WP_REST_API}/logout/`, {
			token: getState().user.token
		})
		dispatch({
			type: 'LOG_OUT',
			payload: { token: '', hasLoginError: false, isLoggingIn: false }
		})
	}
}