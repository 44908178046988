import React, { Component } from 'react'

class LoadingAnimation extends Component {
	constructor(props) {
		super(props);
		this.state = {

		}
	}
	render() {
		let className = 'loading-animation';
		if(this.props.variant === 'front') {
			className += ' front';
		}

		if(this.props.active === false) {
			className += ' inactive';
		} else {
			className += ' active';
		}

		return(
			<div className={className}>
				<div className="line"></div>
				<div className="line"></div>
				<div className="line"></div>
				<div className="line"></div>
				<div className="line"></div>
			</div>
		)
	}
}

export default LoadingAnimation;