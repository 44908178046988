import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { fetchLoans, fetchAllTransactions, fetchEscrow } from '../../actions/index';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

class Borrowers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			borrowers: []
		};
		this.getBalance = this.getBalance.bind(this);
		this.getOutstanding = this.getOutstanding.bind(this);
		this.getReminder = this.getReminder.bind(this);
		this.renderChildren = this.renderChildren.bind(this);
		this.renderDefault = this.renderDefault.bind(this);
	}

	componentDidMount() {
		this.props.fetchLoans();
		this.props.fetchAllTransactions();
		this.props.fetchEscrow();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.accounts.loans.length > 0 && prevState.borrowers.length === 0) {
			let borrowersParsed = this.props.accounts.loans.map((account) => {
				let nameArray = account.full_name.split(':');
				if(nameArray.length > 2) {
					nameArray = nameArray.slice(1);
				}
				let data = {
					primary: nameArray.slice(0, -1)[0],
					project_data: {
						id: account.id,
						children: nameArray.slice(1,-1),
						project_name: nameArray.pop(),
						list_id: account.list_id,
						escrow: account.escrow,
						time_created: account.time_created,
						reminder: account.reminder,
						total_balance: account.total_balance,
						balance: account.balance,
						watchlist: account.watchlist
					}
				}
				return data;
			});
			let primariesMerges = borrowersParsed.reduce((acc, obj) => {
				acc[obj.primary] = acc[obj.primary] || [];
				acc[obj.primary].push(obj.project_data);
				return acc;
			}, {});
			let primariesArray = Object.keys(primariesMerges).map(key => {
				let data = {
					primary: key,
					data: primariesMerges[key]
				}
				return data;
			});
			let borrowers = [];
			primariesArray.forEach((borrower) => {
				let obj = {
					primary: borrower.primary,
					data: [],
					children: []
				}
				let childrenMerged = borrower.data.reduce((acc, obj) => {
					if(obj.children.length > 0) {
						acc[obj.children] = acc[obj.children] || [];
						acc[obj.children].push(obj)
					}
					return acc;
				}, {});
				if(Object.keys(childrenMerged).length === 0 && childrenMerged.constructor === Object) {
					obj.children = null;
					borrower.data.forEach((o) => { delete o.children })
					obj.data = borrower.data;
				} else {
					let childrenArray = Object.keys(childrenMerged).map(key => {
						let data = {
							primary: key,
							data: childrenMerged[key]
						}
						return data;
					});
					obj.children = childrenArray;
					obj.data = null;
				}
				borrowers.push(obj);
			});
			this.setState({ borrowers: borrowers })
		}
	}

	getBalance(project) {
  	let drawn = 0;
  	let transactions = this.props.transactions.all.filter((txn) => {
  		return txn.list_id === project.list_id;
  	})
		transactions.forEach((txn) => {
			if(txn.txn_type === 'JournalEntry') {
				drawn = drawn + parseFloat(txn.amount);
			}
		})
		let balance = parseFloat(project.total_balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		let escrow = '';
		if(project.escrow !== '') {
			let escrowAccount = this.props.accounts.escrow.filter((account) => {
				return account.list_id === project.escrow
			})
			if (escrowAccount.length > 0) {
				escrow = '$' + parseFloat(escrowAccount[0].total_balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			} else {
				escrow = 'None associated';
			}
		} else {
			escrow = 'None associated';
		}
		return(
			<div>
				<p><span>Balance</span><span>${balance}</span></p>
				<p><span>Escrow</span><span>{escrow}</span></p>
			</div>
		)
  }

  getOutstanding(time_created, today) {
  	let t = time_created.split(/[- :]/);
		let created = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
  	let msPerDay = 1000 * 60 * 60 * 24;
  	let utcCreated = Date.UTC(created.getFullYear(), created.getMonth(), created.getDate());
  	let utcToday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
  	let outstanding = Math.floor((utcToday - utcCreated) / msPerDay);
  	return ( <p><span>Days Outstanding</span><span>{outstanding}</span></p> );
  }

	getReminder(project) {
		let thirtyDays = 24 * 3600 * 1000 * 30;
		let today = (new Date()).getTime();
		let start = (new Date(project.time_created)).getTime();
		let reminder = start;
		while(reminder < today) {
			reminder = reminder + thirtyDays;
		}
		if(parseInt(project.reminder) === 1) {
			let daysUntil = Math.floor((reminder - today) / (24 * 60 * 60 * 1000)) + ' days remaining';
			if(Math.floor((reminder - today) / (24 * 60 * 60 * 1000)) === 0) {
				daysUntil = 'Due today';
			}
			return ( <p><span>30 Day Reminder</span><span>{daysUntil}</span></p> );
		}
	}

	renderChildren(borrower) {
		return(
			<ListItem className="borrowers__list-item" key={borrower.primary}>
				<h2>{borrower.primary}</h2>
				{ borrower.children.map((child, i) => { 
					return( 
						<div className="borrower__child" key={child + '_' + i}>
							<h3>{child.primary}</h3>
							{child.data.map((project) => {
								let className = 'borrowers__project';
								if(parseInt(project.watchlist) === 1) {
									className = 'borrowers__project watchlist';
								}
								return( 
									<div className={className} key={project.list_id}>
										<Link to={`/single/${project.list_id}`}>{project.project_name}</Link>
										<div className="borrowers__list-meta">
											{this.getReminder(project)}
											{this.getOutstanding(project.time_created, new Date())}
											{this.getBalance(project)}
										</div>
									</div>
								)
							})}
						</div>
					)
				})}
			</ListItem>
		)
	}

	renderDefault(borrower) {
		if(borrower.primary !== 'Loans Receivable') {
			let borrowerName = borrower.primary;
			if(borrowerName === 'undefined') {
				borrowerName = 'Loans Receivable';
			}
			return(
				<ListItem className="borrowers__list-item" key={borrower.primary}>
					<h2>{borrowerName}</h2>
					<div className="borrowers__subs">
						{borrower.data.map((project) => {
							let className = 'borrowers__project';
							if(parseInt(project.watchlist) === 1) {
								className = 'borrowers__project watchlist';
							}
							return( 
								<div className={className} key={project.list_id}>
									<Link to={`/single/${project.list_id}`}>{project.project_name}</Link>
									<div className="borrowers__list-meta">
										{this.getOutstanding(project.time_created, new Date())}
										{this.getReminder(project)}
										{this.getBalance(project)}
									</div>
								</div>
							)
						})}
					</div>
				</ListItem>
			)
		}
	}

	render() {
		if(this.state.borrowers.length > 0) {
			return (
				<div className="borrowers">
					<h1>Borrowers</h1>
					<Paper className="paper">
						<List className="borrowers__list">
							{this.state.borrowers.map((obj) => {
								if(obj.children) { return this.renderChildren(obj) }
								else { return this.renderDefault(obj) }
							})}
						</List>
					</Paper>
	      </div>
			);
		} else {
			return (
				<LoadingAnimation active={true} />
			);
		}
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchLoans, fetchAllTransactions, fetchEscrow }, dispatch);
}

function mapStateToProps({ accounts, transactions }) {
	return { accounts, transactions };
}

export default connect(mapStateToProps, mapDispatchToProps)(Borrowers);