import React, { Component } from 'react';
import Marker from '../../components/Marker/Marker';
import redCircle from '../../assets/circle-red.svg';
import greenCircle from '../../assets/circle-green.svg';
import GoogleMapReact from 'google-map-react';
import Paper from '@material-ui/core/Paper';

class ProjectMap extends Component {
	constructor(props) {
		super(props);
		this.state = {
			center: {
				lat: 38.6825321,
      	lng: -85.1743834
			},
			newCenter: {
				lat: '',
				lng: ''
			},
			zoom: 8,
			markers: [],
			green: true,
			red: true
		};
		this.renderMarkers = this.renderMarkers.bind(this);
		this.toggleWatchlist = this.toggleWatchlist.bind(this);
	}

	renderMarkers(account, type) {
		if(account !== null) {
			let img = !!parseInt(account.watchlist) ? redCircle : greenCircle;
			if(this.state.green === false && img === greenCircle) return;
			if(this.state.red === false && img === redCircle) return;
			return <Marker 
				key={account.id} 
				lat={account.lat}	
				lng={account.lng} 
				circle={img}
				projectName={account.name}
				listID={account.list_id}
				popupActive={false}
				markerType="marker"/>
		}
	}

	toggleWatchlist(type) {
		this.setState({
			[type]: !this.state[type]
		});
	}

	onChildClick = (key, childProps) => {
		this.setState({
			center: {
				lat: parseFloat(childProps.lat),
				lng: parseFloat(childProps.lng)
			}
		})
  }

	render() {
		if(this.state.markers == null) {
			return;
		}
		return (		
			<div className="map">
				<h2>Locations</h2>
				<Paper className="paper">
					<div style={{ height: '100%', width: '100%' }}>
						<GoogleMapReact
							bootstrapURLKeys={{ key: 'AIzaSyDsEXS4m8yW8JGVOtq-Qzs3CjQN8A_w0BQ' }}
							center={this.state.center}
							zoom={this.state.zoom}
							onChildClick={this.onChildClick}
							>
							{this.props.accountsWithCoords.map(this.renderMarkers)}
							</GoogleMapReact>
					</div>
				</Paper>
				<div className="markers__toggle-container">
					<div className="markers__toggle" type="green" onClick={() => this.toggleWatchlist('green')} >
						<Marker 
							className="markers__toggle-marker" 
							circle={greenCircle} 
							activeStatus={this.state.green}
							markerType="legend" />
						<span>Good Projects</span>
					</div>
					<div className="markers__toggle" type="red" onClick={() => this.toggleWatchlist('red')} >
						<Marker 
							className="markers__toggle-marker" 
							circle={redCircle}
							activeStatus={this.state.red}
							markerType="legend" />
						<span>Watchlist</span>
					</div>
				</div>
			</div>
		)
	}
}

export default ProjectMap;