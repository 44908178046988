import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteTransaction } from '../../actions/index';
import { Link } from 'react-router-dom';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMore from '@material-ui/icons/ExpandMore';
import History from '@material-ui/icons/History';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import PiggyBank from '../../assets/PiggyBank';
import Calendar from '../../assets/Calendar';
import QuickBooks from '../../assets/QuickBooks';

const filterList = [ 
	{
		'value': 'date_asc',
		'label': 'Date (ASC)'
	},
	{
		'value': 'date_desc',
		'label': 'Date (DESC)'
	}
]

class TxnList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			type: this.props.type,
			toRemove: null,
			willRemove: false,
			removePrompt: 'txn-list__remove-prompt-container',
			txnToDisplay: [],
			anchorEl: null,
			sort: 'date_asc',
			loader: false
		}
		this.deleteCancel = this.deleteCancel.bind(this);
		this.deletePrepare = this.deletePrepare.bind(this);
		this.deleteTransaction = this.deleteTransaction.bind(this);
		this.paginateTxns = this.paginateTxns.bind(this);
		this.renderList = this.renderList.bind(this);
		this.renderLink = this.renderLink.bind(this);
		this.resort = this.resort.bind(this);
	}

	componentDidMount() {
		if(this.props.txns.length < 1) return;
		this.setState({
			txnToDisplay: this.resort(this.props.txns, this.state.sort)
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.state.txnToDisplay.length !== this.props.txns.length) {
			this.setState({
				txnToDisplay: this.resort(this.props.txns, this.state.sort)
			})
		}
		if(this.state.sort !== prevState.sort) {
			this.setState({
				txnToDisplay: this.resort(this.props.txns, this.state.sort)
			});
		}
		if(this.state.willRemove === true) {
			if(this.props.transactions.status === 200) {
  			this.setState({ 
  				toRemove: null,
  				removePrompt: 'txn-list__remove-prompt-container',
  				willRemove: false,
  				loader: false
  			}, () => {
  				this.props.handler();
  			})
  		}
		}
	}

	deleteCancel() {
		this.setState({
			toRemove: null,
			removePrompt: 'txn-list__remove-prompt-container'
		})
	}

	deletePrepare(id) {
		this.setState({ 
			toRemove: id,
			removePrompt: 'txn-list__remove-prompt-container active'
		})
	}

	deleteTransaction() {
		this.setState({ 
			willRemove: true,
			loader: true
		}, () => {
			this.props.deleteTransaction(this.state.toRemove);
		})
	}

	handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

	filterChange = name => event => {
		this.setState({
      sort: name,
      anchorEl: null
    });
	}

	noTransactions() {
		return (
			<ListItem key={'txn-false'} className="txn-list__list-item">
				<p>This project does not have any events to display.</p>
			</ListItem>
		)
	}

	paginateTxns() {
		let currentPage = this.state.offset;
		let nextPage = currentPage + this.state.perPage;
		this.setState({
			offset: nextPage
		})
	}

	renderIcon(txn) {
		if(this.props.type === 'history') {
			return ( <History /> )
		}
		switch(txn.txn_type) {
			case 'draw':
				return ( <PiggyBank /> )
			case 'inspection':
				return ( <CheckCircleOutline /> )
			case 'thirty_days':
				return ( <Calendar /> )
			case 'review':
				return ( <Calendar /> )
			case 'quickbooks':
				return ( <QuickBooks /> )
			default:
				return ( <div></div> )
		}
	}

	renderLink(txn) {
		if(this.props.showProjects === true) {
			return( <Link to={`/single/${txn.list_id}`}>{txn.full_name.split(':').pop()}</Link> );
		}
	}

	renderList(txn) {
		let date = '';
		if(txn.txn_type === 'thirty_days') {
			date = new Date(txn.txn_date);
		} else {
			let t = txn.txn_date.split(/[- :]/);
			date = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
		}

		let prettyDate = date.getMonth()+1 + '/' + date.getDate() + '/' + date.getFullYear();
		let amount = '';
		if(parseInt(txn.amount) !== 0) {
			amount = '$'+ parseFloat(txn.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		}
		let className = 'txn-list__list-item ' + txn.txn_type;;

		let txnTitle = txn.txn_title;
		if(txnTitle === '' && txn.txn_type === 'quickbooks') {
			txnTitle = 'From QuickBooks';
		} else if(txnTitle === null && txn.txn_type === 'quickbooks') {
			txnTitle = 'From QuickBooks';
		}

		return (
			<ListItem key={txn.id} className={className}>
				<div className="txn-list__remove-item" onClick={() => { this.deletePrepare(txn.id) }} >
					<DeleteForever />
				</div>
				<div className="txn-list__icon-heading">
					{this.renderIcon(txn)}
					<h3>{ txnTitle }</h3>
				</div>
				<div className="txn-list__txn-info">
					{this.renderLink(txn)}
					<p>{ prettyDate }</p>
					<p>{ amount }</p>
					<p>{ txn.memo }</p>
				</div>
			</ListItem>
		)		
	}

	resort(txns, sort) {
		return txns.sort((a,b) => {
			let at = '';
			let bt = '';
			let adate = '';
			let bdate = '';
			if(a.txn_type === 'thirty_days') {
				adate = new Date(a.txn_date);
			} else {
				at = a.txn_date.split(/[- :]/);
				adate = new Date(at[0], at[1]-1, at[2], at[3], at[4], at[5]);
			}
			if(b.txn_type === 'thirty_days') {
				bdate = new Date(b.txn_date);
			} else {
				bt = b.txn_date.split(/[- :]/);
				bdate = new Date(bt[0], bt[1]-1, bt[2], bt[3], bt[4], bt[5]);
			}
			switch(sort) {
				case 'date_asc':
					return adate - bdate;
				case 'date_desc':
					return bdate - adate;
				default:
					return adate - bdate;
			}
		});
	}

	render() {
		const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
		if(this.props.txns !== null) {
			let className = 'txn-list__container ' + this.props.type;
			let txns = this.state.txnToDisplay.length ? this.state.txnToDisplay.map(this.renderList) : this.noTransactions();
			return(
				<div className={className}>
					<LoadingAnimation variant="front" active={this.state.loader} />
					<div className={this.state.removePrompt}>
						<Paper className="txn-list__remove-prompt">
							<p>Are you sure you want to remove this transaction permanently?</p>	
							<Button onClick={this.deleteCancel} className="txn-list__remove-cancel">Cancel</Button>
							<Button onClick={this.deleteTransaction} className="txn-list__remove-confirm">Remove permanently</Button>
						</Paper>
					</div>
					<div className="txn-list__heading">
						<h2>{this.props.type}</h2>
						<div className="filter__container">
							<div className="filter__toggle" onClick={this.handleClick}>
								<span>Filter</span>
								<ExpandMore />
							</div>
							<Menu
			          className="filter__menu"
			          anchorEl={anchorEl}
			          open={open}
	          		onClose={this.handleClose}
			        >
			          { filterList.map((option) => {
			          	let selected = false;
			          	if(this.state.sort === option.value) {
			          		selected = true;
			          	}
			          	return (
				            <MenuItem 
				            	className="filter__menu-item"
				            	onClick={this.filterChange(option.value)}
				            	selected={selected}
				            	key={option.value} >
				              {option.label}
				            </MenuItem>
				          	)
				          }) 
				        }
			        </Menu>
				    </div>
				  </div>
					<Paper className="paper">
					<div className="txn-list__txns">
						<List className="txn-list">
							{ txns }
						</List>
					</div>
					</Paper>
				</div>
			);
		} else {
			return (
				<LoadingAnimation active={true} />
			)
		}
		
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ deleteTransaction }, dispatch);
}

function mapStateToProps({ transactions }) {
	return { transactions };
}

export default connect(mapStateToProps, mapDispatchToProps)(TxnList);