import axios from 'axios';

export const fetchLoans = () => {
	return(dispatch, getState) => {
        return axios.get(`${process.env.REACT_APP_WP_REST_API}/accounts`, {
          params: {
            token: getState().user.token,
            account_type: 'loans_receivable'
          }
        })
        .then((response) => {
        	dispatch({
        		type: 'FETCH_LOANS',
        		payload: response
        	})
        })
        .catch((error) => {
        	console.log(error);
     	})
    }
}

export const fetchEscrow = () => {
    return(dispatch, getState) => {
        return axios.get(`${process.env.REACT_APP_WP_REST_API}/accounts`, {
          params: {
            token: getState().user.token,
            account_type: 'escrow'
          }
        })
        .then((response) => {
            dispatch({
                type: 'FETCH_ESCROW',
                payload: response
            })
        })
        .catch((error) => {
            console.log(error);
        })
    }
}