import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { fetchAllTransactions, fetchLoans } from '../../actions/index';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import TxnList from '../../components/TxnList/TxnList';

class Reminders extends Component {
	constructor(props) {
		super(props);
		this.state = {
			reminders: null
		}
		this.getReminder = this.getReminder.bind(this);
	}
	
	componentDidMount() {
		this.props.fetchLoans();
		this.props.fetchAllTransactions();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.transactions.all.length > 0 && this.props.accounts.loans.length > 0 && prevState.reminders === null) {
			let accountsWithReminders = this.props.accounts.loans.filter((account) => {
				 return !!+parseInt(account.reminder) === true;
			})
			let reminders = this.props.transactions.all.filter((txn) => {
				let t = txn.txn_date.split(/[- :]/);
  			return new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]) > new Date();
  		})
			accountsWithReminders.forEach((account) => {
				let reminder = this.getReminder(account);
				let daysUntil = reminder.daysUntil + ' days left';
				if(reminder.daysUntil === 0) {
					daysUntil = 'Due today';
				}
				let reminderTxn = {
					list_id: account.list_id,
					full_name: account.full_name,
					txn_title: '30 Day Reminder',
					txn_date: reminder.date,
					memo: daysUntil,
					txn_type: 'thirty_days',
					id: 'thirty-days-' + account.id,
					amount: 0
				}
				reminders.push(reminderTxn);
			})
			this.setState({ reminders: reminders })
		}
	}
	
	getReminder(account) {
		let thirtyDays = 24 * 3600 * 1000 * 30;
		let today = (new Date()).getTime();
		let t = account.time_created.split(/[- :]/);
		let start = (new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]).getTime());
		let reminder = start;
		while(reminder < today) {
			reminder = reminder + thirtyDays;
		}
		return {
			date: new Date(reminder),
			daysUntil: Math.floor((reminder - today) / (24 * 60 * 60 * 1000))
		}
	}

	render() {
		if(this.state.reminders !== null) {
			return(
				<div className="reminders">
					<TxnList 
						showProjects={true}
						type="reminders" 
						txns={this.state.reminders}
					/>
				</div>
			);
		} else {
			return (
				<LoadingAnimation active={true} />
			);
		}
		
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchAllTransactions, fetchLoans }, dispatch);
}

function mapStateToProps({ transactions, accounts }) {
	return { transactions, accounts,};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reminders));