import React from 'react';

const PiggyBank = (props) => {
	return (
		<svg aria-hidden="true" data-prefix="fal" data-icon="piggy-bank" className="svg-inline--fa fa-piggy-bank fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
			<path fill="#FFF" d="M432 256c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16zm128-32h-29.5c-11.1-25.3-28.7-46.9-50.5-63.4V96h-16c-30.3 0-57.8 10.1-80.9 26.2.4-3.4.9-6.7.9-10.2C384 50.1 333.9 0 272 0S160 50.1 160 112c0 9.7 1.6 18.9 4 27.9C115 159.7 78 203 67.2 256H56c-14.8 0-26.5-13.5-23.5-28.8C34.7 215.8 45.4 208 57 208h1c3.3 0 6-2.7 6-6v-20c0-3.3-2.7-6-6-6-28.5 0-53.9 20.4-57.5 48.6C-3.9 258.8 22.7 288 56 288h8c0 52.2 25.4 98.1 64 127.3V496c0 8.8 7.2 16 16 16h112c8.8 0 16-7.2 16-16v-48h64v48c0 8.8 7.2 16 16 16h112c8.8 0 16-7.2 16-16v-80.9c11.7-9 22.4-19.3 31.3-31.1H560c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16zM272 32c44.2 0 80 35.8 80 80 0 5.5-.6 10.8-1.6 16H224c-10.1 0-19.9 1.2-29.5 3-1.6-6.1-2.5-12.4-2.5-19 0-44.2 35.8-80 80-80zm272 320h-48.7c-17 22.4-17 24.1-47.3 47.3V480h-80v-64H240v64h-80v-80.7c-64.9-49-64-93.5-64-111.3 0-70.6 57.4-128 128-128h161.7c16.9-13.5 33.2-26.5 62.3-30.8v47.3c39.7 30.1 44 39.3 61.6 79.5H544v96z">
			</path>
		</svg>
	)
}

export default PiggyBank;