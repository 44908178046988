import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logOut, fetchLoans } from '../../actions/index';
import Autosuggest from 'react-autosuggest';
import AppBar from '@material-ui/core/AppBar';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import IconSearch from '@material-ui/icons/Search';
import logo from '../../assets/logo.svg';

const getSuggestions = (value, accounts) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  return inputLength === 0 ? [] : accounts.filter(account =>
    account.name.toLowerCase().slice(0, inputLength) === inputValue
  );
};

const getSuggestionValue = (suggestion) => {
  return suggestion.name;
};

class Nav extends Component {
	constructor(props) {
		super(props);
		this.state = {
			navClass: 'nav',
			top: false,
	    left: false,
	    bottom: false,
	    right: false,
	    value: '',
	    suggestions: [],
	    menuStatus: 'nav__menu-admin'
		}
		this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
		this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
		this.renderSuggestion = this.renderSuggestion.bind(this);
		this.toggleSearch = this.toggleSearch.bind(this);
	}

	componentDidMount() {
		document.addEventListener('scroll', _.throttle(() => {
      const scroll = window.scrollY;
      if (scroll > 1) {
        this.setState({ navClass: 'nav scrolled' })
      } else {
        this.setState({ navClass: 'nav' })
      }
    }, 500));
		this.props.fetchLoans();
	}

	onChange = (event, { newValue }) => {
	  this.setState({ value: newValue })
	}

  onSuggestionsClearRequested(){
  	this.setState({
  		suggestions: []
  	})
  };

  onSuggestionsFetchRequested({value}) {
  	this.setState({
  		suggestions: getSuggestions(value, this.props.accounts.loans)
  	})
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
      value: ''
    });
  };

  toggleSearch() {
  	if(this.state.menuStatus === 'nav__menu-admin') {
  		this.setState({ menuStatus: 'nav__menu-admin active' })
  	} else {
  		this.setState({ menuStatus: 'nav__menu-admin' })
  	}
  }

  renderSuggestion(suggestion) {
	  return (
	  	<ul className="suggestion__list">
		  	<MenuItem key={suggestion.id}>
			    <Link to={`/single/${suggestion.list_id}`} onClick={this.toggleDrawer('right', false)}>
			      <div>
			        {suggestion.name}
			      </div>
			    </Link>
			   </MenuItem>
		   </ul>
	  );
	};

  render() {
    return (
      <div className={this.state.navClass}>
      	<AppBar className="nav__appbar">
      		<div className="container flex-container">
	      		<Link to="/">
		      		<img className="nav__logo" src={logo} alt="MMLending" />
		      	</Link>
	      		<div className="nav__desktop">
	      			<div className="nav__upper">
	      				<List className={this.state.menuStatus}>
		      				<ListItem>
		      					<a href="http://app.mmlending.com/wp/wp-admin/admin.php?page=loans-receivable">Admin</a>
		      				</ListItem>
		      				<ListItem>
		      					<a href="http://app.mmlending.com/wp/wp-admin/profile.php">My Account</a>
		      				</ListItem>
		      				<ListItem>
		      					<span onClick={this.props.handler} >Log out</span>
		      				</ListItem>
		      				<ListItem className="nav__item-icon">
		      					<div className='icon__nav' onClick={this.toggleSearch}>
		      						<IconSearch />
		      					</div>
		      				</ListItem>
		      				<ListItem>
		      					<div className="nav__search-desktop">
			      					<Autosuggest
												suggestions={this.state.suggestions}
												onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
												onSuggestionsClearRequested={this.onSuggestionsClearRequested}
												getSuggestionValue={getSuggestionValue}
												renderSuggestion={this.renderSuggestion}
												inputProps={{
													value: this.state.value,
													onChange: this.onChange
												}}
											/>
										</div>
		      				</ListItem>
	      				</List>
	      			</div>
	      			<div className="nav__lower">
	      				<List className="nav__menu-main">
		      				<ListItem>
		      					<Link to="/">Projects</Link>
		      				</ListItem>
		      				<ListItem>
		      					<Link to="/borrowers">Borrowers</Link>
		      				</ListItem>
		      				<ListItem>
		      					<Link to="/reminders">Reminders</Link>
		      				</ListItem>
		      			</List>
	      			</div>
	      		</div>
	      	</div>
      		<div className="nav__mobile">
      			<div className="container">
	      			<div 
	      				className="ocn__open ocn__button" 
	      				aria-label="Open drawer" 
	      				onClick={this.toggleDrawer('right', true)}
	      			>
				      	<div className="line"></div>
				      	<div className="line"></div>
				      	<div className="line"></div>
				       </div>
			      	<SwipeableDrawer
			      		anchor="right"
			      		open={this.state.right}
			      		onClose={this.toggleDrawer('right', false)}
			      		onOpen={this.toggleDrawer('right', true)}
			      		className="nav__drawer"
			      		classes={{ paper: 'nav__paper' }}
			      	>
		      		<div
		      			tabIndex={0}
		            role="button"
		            className='nav__drawer'
		      			>
		      			<div className="ocn__search-container">
			      			<IconSearch />
									<Autosuggest
										suggestions={this.state.suggestions}
										onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
										onSuggestionsClearRequested={this.onSuggestionsClearRequested}
										getSuggestionValue={getSuggestionValue}
										renderSuggestion={this.renderSuggestion}
										inputProps={{
											value: this.state.value,
											onChange: this.onChange
										}}
										/>
								</div>
		      			<List className="ocn__menu ocn__menu-main">
		      				<ListItem>
		      					<Link onClick={this.toggleDrawer('right', false)} to="/">Projects</Link>
		      				</ListItem>
		      				<ListItem>
		      					<Link onClick={this.toggleDrawer('right', false)} to="/borrowers">Borrowers</Link>
		      				</ListItem>
		      				<ListItem>
		      					<Link onClick={this.toggleDrawer('right', false)} to="/reminders">Reminders</Link>
		      				</ListItem>
		      			</List>
		      			<List className="ocn__menu ocn__menu-admin">
		      				<ListItem>
		      					<a href="http://staging.mswhost.com/mmlending.com/wp/wp-admin/admin.php?page=loans-receivable">Admin</a>
		      				</ListItem>
		      				<ListItem>
		      					<a onClick={this.toggleDrawer('right', false)} href="http://staging.mswhost.com/mmlending.com/wp/wp-admin/profile.php">My Account</a>
		      				</ListItem>
		      				<ListItem>
		      					<span onClick={this.props.handler}>Log out</span>
		      				</ListItem>
		      			</List>
		      		</div>
		      		<div className="ocn__close ocn__button" onClick={this.toggleDrawer('right', false)}></div>
		      		</SwipeableDrawer>
		      	</div>
		      </div>
	      </AppBar>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logOut, fetchLoans }, dispatch);
}

function mapStateToProps({ accounts }) {
  return { accounts };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));