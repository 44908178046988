import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchLoans, fetchEscrow } from '../../actions/index';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import ProjectList from '../../components/ProjectList/ProjectList';
import ProjectMap from '../../components/ProjectMap/ProjectMap';
import Sidebar from '../../components/Sidebar/Sidebar';

class Overview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			accountsWithCoords: [],
			activeAccounts: null,
			loansOut: null,
			accountsCount: null,
			totalBalance: null,
			totalEscrow: null,
			lastUpdated: null
		};
		this.returnAccountsWithCoords = this.returnAccountsWithCoords.bind(this);
	}

	componentDidMount() {
		this.props.fetchLoans();
		this.props.fetchEscrow();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.accounts.loans.length > 0 && this.props.accounts.escrow.length > 0 && this.state.accountsCount === null) {
			let total = 0;
			this.props.accounts.loans.forEach((account) => {
				if(parseInt(account.is_active) > 0) {
					total = total + parseFloat(account.balance);
				}
			});
			let accountsWithCoords = [];
			let loansOut =[];
			let activeAccounts = [];
			let totalBalance = '';
		 	this.props.accounts.loans.forEach((account) => {
		 		if(parseFloat(account.balance) > 0) {
					loansOut.push(account);
				}
				if(parseInt(account.is_active) > 0) {
					activeAccounts.push(account);
				}
				if(account.list_id === '80000022-1185041371') {
					totalBalance = account.total_balance;
				}
		 	});
		 	let escrowAccount = this.props.accounts.escrow.filter((account) => {
		 		return account.list_id === '800002FE-1349829728';
		 	})
		 	this.setState({
		 		accountsWithCoords: accountsWithCoords,
		 		loansOut: loansOut,
		 		activeAccounts: activeAccounts,
		 		accountsCount: this.props.accounts.loans.length,
		 		totalBalance: totalBalance,
		 		totalEscrow: escrowAccount[0].total_balance,
		 		lastUpdated: this.props.accounts.last_updated
		 	})
		}
	}

	returnAccountsWithCoords(account) { 
		return account.lat !== '' && account.lng !=='';
	}

	render() {
		if(this.props.accounts.loans.length > 0 && this.props.accounts.escrow.length > 0) {
			return (
				<div className="overview__container">
					<div className="overview__map-sidebar">
		        <ProjectMap 
		        	accountsWithCoords={this.props.accounts.loans.filter(this.returnAccountsWithCoords)}
		        />
		        <Sidebar 
		        	activeAccounts={this.state.activeAccounts} 
		        	loansOut={this.state.loansOut}
		        	totalBalance={this.state.totalBalance}
		        	totalEscrow={this.state.totalEscrow}
		        	lastUpdated={this.state.lastUpdated}
		        />
		      </div>
	        <ProjectList 
	        	accounts={this.props.accounts.loans}
	        />
	      </div>
			);
		} else {
			return (
				<LoadingAnimation active={true} />
			)
		}
		
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetchLoans, fetchEscrow }, dispatch);
}

function mapStateToProps({ accounts }) {
	return { accounts };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Overview));